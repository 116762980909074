<template>
    <div class="contacts w-100 black">
      <div class="w-100 d-flex justify-content-center black">
        <v-form id="policy-form" class="mt-lg-16" style="display: block; margin: auto;">
          <div class="form-content">
            <v-btn text fab @click="$emit('close')" style="float: right;" class="d-none d-sm-block"><v-icon
                large>mdi-close</v-icon></v-btn>
            <v-btn text fab @click="$emit('close')" style="top: -60px; right: -30px; position: absolute;"
              class="d-sm-none"><v-icon large>mdi-close</v-icon></v-btn>
            <v-row class="policy-content" @scroll="onScroll">
              <v-col cols="12" class="pb-16 mb-16">
                <div class='intro mt-2'>
                  <p class='w-100 text-center'>
                    <strong>
                      TERMOS E CONDIÇÕES EM INGLES
                    </strong>
                  </p>
                  <v-card class="elevation-0 transparent">
                    <v-card-text>
                      Estes são os termos e condições de acesso e utilização da plataforma IGNOTO. É importante que o leia
                      atentamente. Ao aceitar estes termos e condições, está a celebrar um negócio jurídico nos termos da
                      lei
                      portuguesa. Caso não os aceite, não poderá utilizar esta plataforma. A Dreamdeal Technologies,
                      Unipessoal Lda.,
                      atualizou estes Termos e Condições em 6 de maio de 2024, com entrada em vigor na mesma data.
                    </v-card-text>
                  </v-card>
                  <div>
                    <p class="w-100 text-center">
                      Índice
                    </p>
                  </div>
                  <div class='index mt-5'>
                    <ol type="1">
                      <li><a href='#object'>Objeto</a></li>
                      <li><a href='#services'>Serviços</a></li>
                      <li><a href='#payment'>Pagamento</a></li>
                      <li><a href='#sla'>Serviços Mínimos/SLA</a></li>
                      <li><a href='#use_of_service'>Uso de Serviço</a></li>
                      <li><a href='#force'>Força Maior</a></li>
                      <li><a href='#responsibilty'>Garantia e Responsabilidade Civil</a></li>
                      <li><a href='#close_policy'>Política de Cancelamento</a></li>
                      <li><a href='#final'>Disposições finais</a></li>
                      <li><a href='#gloss'>Glossário</a></li>
                    </ol>
                  </div>
                </div>
                <div id="object">
                  <v-card class="transparent">
                    <v-card-title>1. Objeto</v-card-title>
                    <v-card-text>
                      1.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A concordância com estes Termos e Condições estabelece uma
                      relação contratual (“Contrato”) entre o
                      Cliente (conforme definido infra, no Glossário), e a Dreamdeal Technologies, Unipessoal Lda.,
                      sociedade por
                      quotas registada nos termos da lei portuguesa sob o NIPC 516178733, com sede na Rua Alfredo Keil,
                      371,
                      3E, 4150-048 Porto (doravante designada apenas por “Dreamdeal”). Cliente e Dreamdeal são doravante
                      também designados por “Parte” e, coletivamente, por “Partes”.<br /><br />
                      1.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estes termos e condições entram em vigor na data em que são
                      assinados pelo Cliente ou pelo seu
                      Representante, ou na data em que declaram a sua aceitação, independentemente da forma como se
                      manifesta essa declaração. <br /><br />
                      1.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal concede ao Cliente o direito de acesso temporário,
                      mundial, não-exclusivo e não SUB
                      licenciável ao software IGNOTO, nos Termos e Condições aqui estabelecidos, limitado ao período de um
                      ano, renovável sucessivamente por igual período, no silêncio das partes
                      1.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No caso da subscrição experimental gratuita, o direito referido
                      no ponto 1.3. apenas é transmitido pelo
                      período de 30 dias.<br /><br />
                      1.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal não transmite ao Cliente, Colaborador ou Utilizador a
                      titularidade de direitos de autor, de
                      propriedade industrial ou de outros direitos conexos<br /><br />
                      1.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O direito de acesso referido no ponto 1.3. pode ser retransmitido
                      pelo Cliente aos seus Colaboradores, nos
                      termos em que é concedido, mas apenas no âmbito da respetiva relação laboral ou de prestação de
                      serviços,
                      limitado pelo período e modalidade de subscrição dos serviços contratualizados pelo Cliente.
                      1.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;É expressamente vedada a transmissão do direito de acesso
                      referido no ponto 1.3., fora dos casos previstos
                      no ponto 1.5.<br /><br />
                      1.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Cliente não pode, nem pode permitir a Colaboradores ou
                      terceiros:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;copiar, reproduzir, distribuir, modificar, adaptar, alterar,
                      traduzir ou criar trabalhos derivados do software
                      ou aplicações;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ceder, SUB licenciar, ceder em locação, arrendar, emprestar,
                      transferir, divulgar ou de outra forma
                      disponibilizar o referido software ou outros trabalhos;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ou incorporar o software noutro software;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;proceder à montagem na ordem inversa, descompilar e desmontar o
                      software, ou de outra forma tentar
                      obter o seu código fonte ou a sua natureza algorítmica, ou descodificar, decifrar ou neutralizar
                      qualquer
                      medida de segurança do software, remover ou contornar a proteção do software sem autorização;
                      <br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;executar qualquer ação que implique que o software ou um trabalho
                      dele derivado seja licenciado ao
                      abrigo dos termos de código aberto, incluindo, entre outras, combinar o software ou um trabalho dele
                      derivado com software de código aberto por incorporação, ligação ou de outra forma; <br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;criar um trabalho derivado do software IGNOTO;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;disponibilizar ou reproduzir de qualquer forma, o código fonte do
                      software IGNOTO;<br /><br />
                      1.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal reserva-se o direito de alterar unilateralmente este
                      Termos e Condições.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="services">
                  <v-card class="transparent">
                    <v-card-title>2. Serviços</v-card-title>
                    <v-card-text>
                      2.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A IGNOTO é uma plataforma de software online, de gestão de
                      denúncias.<br /><br />
                      2.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados estão disponíveis online através da área de cliente na
                      respetiva aplicação e armazenados numa
                      cloud pertencente à Dreamdeal, em servidores fisicamente localizados na União Europeia, cumprindo
                      com
                      todas as diretrizes de RGPD e de cyber segurança em vigor na UE.<br /><br />
                      2.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal disponibiliza planos de subscrição padronizados,
                      catalogados em ignoto.eu e descritos nos
                      parágrafos seguintes, assim como planos de subscrição à medida (“Plano Tailored”), regulados em
                      Termos
                      e Condições Específicos.
                      2.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Cliente vincula-se a um determinado plano de subscrição quando
                      emite uma declaração negocial nesse
                      sentido, independentemente da forma.<br /><br />
                      2.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O <b>Plano i1</b>, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para empresas privadas ou instituições públicas que
                      tenham no máximo 99 colaboradores
                      ao seu serviço;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma
                      IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um máximo de 4 GB.<br /><br />
                      2.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O <b>Plano i2</b>, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para empresas privadas ou instituições públicas que
                      tenham no máximo 299 colaboradores
                      ao seu serviço;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma
                      IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um máximo de 8 GB.<br /><br />
                      2.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O <b>Plano i3</b>, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para empresas privadas ou instituições públicas que
                      tenham no máximo 999 colaboradores
                      ao seu serviço;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma
                      IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um máximo de 12 GB<br /><br />
                      2.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Plano iP1, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para instituições públicas: Municípios com uma
                      população até 25.000 habitantes.<br />Fundações, Hospitais, IPSS, entre outros com uma rede de até
                      25.000 colaboradores/associados.<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um
                      máximo de 6 GB.<br /><br />
                      2.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Plano iP2, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para instituições públicas: Municípios com uma
                      população entre 25.000 e 55.000 habitantes. Fundações, Hospitais, IPSS, entre outros com uma rede
                      entre 25.000 e 55.000 colaboradores/associados.
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um
                      máximo de 10 GB.<br /><br />
                      2.10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Plano iP3, tem as seguintes particularidades:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este plano é para instituições públicas: Municípios com uma
                      população acima de 55.000 habitantes. Fundações, Hospitais, IPSS, entre outros com uma rede acima de
                      55.000 colaboradores/associados.
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O armazenamento dos dados do Cliente e dos respetivos Colaboradores
                      introduzidos na plataforma IGNOTO é efetuado em Disco SSD pertencente à Dreamdeal, limitado a um
                      máximo de 15 GB.<br /><br />
                      2.11. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Todos os planos incluem: Um modelo de apresentação
                      personalizável (é possível alterar o layout da capa
                      de apresentação (cor, fonte, textos); Incorporação via snipet ou link de acesso; Acesso ao painel de
                      administrador; Notificações e controlo de SLAs; Integração SSO; Número de administradores
                      ilimitados;
                      Número de denúncias ilimitado; Autenticação 2FA; Encriptação E2E; Processos em conformidade com a
                      Certificação ISO 27001; Suporte 24horas.<br /><br />
                      2.12. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O pagamento dos planos de subscrição é efetuado nos termos do
                      capítulo seguinte
                    </v-card-text>
                  </v-card>
                </div>
                <div id="payment">
                  <v-card class="transparent">
                    <v-card-title>3. Pagamento</v-card-title>
                    <v-card-text>
                      3.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Cliente é responsável pelo pagamento de todos os preços,
                      impostos e encargos devidos em contrapartida
                      pelos Serviços, nos termos da modalidade de subscrição contratualizada.<br /><br />
                      3.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O pagamento é feito por referência Multibanco, cartão de crédito,
                      débito direto, ou ainda, por transferência
                      bancária (mediante pedido prévio por escrito para o email: info@ignoto.eu) para o IBAN
                      disponibilizado pela
                      Dreamdeal.<br /><br />
                      3.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ao subscrever um dos nossos planos, o Cliente paga uma anuidade,
                      cuja data de vencimento é o dia seguinte
                      à data de concordância com os presentes Termos e Condições.
                      3.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os preços base estabelecidos para cada plano, são os
                      seguintes.<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>EMPRESAS PRIVADAS</b><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano i1</b>: 719,88€ por ano + IVA
                      à taxa legal em vigor, equivalente a 59,99€/mês + IVA;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano i2</b>: 1.199,88€ por ano +
                      IVA à taxa legal em vigor, equivalente a 99,99€/mês +IVA;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano i3</b>: 2.039,88€ por ano +
                      IVA à taxa legal em vigor, equivalente a 169,99€/mês + IVA.<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <b>EMPRESAS PÚBLICAS</b><br /><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano iP1</b>: 839,88€ por ano +
                      IVA à taxa legal em vigor, equivalente a 69,99€/mês + IVA;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano iP2</b>: 1.439,88€ por ano +
                      IVA à taxa legal em vigor, equivalente a 119,99€/mês +IVA;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Plano iP3</b>: 2.399,88€ por ano +
                      IVA à taxa legal em vigor, equivalente a 199,99€/mês + IVA.<br /><br />
  
  
                      3.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Promoções: Oferta promocional por tempo <b>limitado</b>, sendo
                      válida apenas para subscrições realizadas no
                      período em que exista uma sinalização, na página das subscrições, sobre a promoção:
                      <br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;É necessário efetuar a subscrição durante a vigência da
                      promoção;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esta promoção poderá ser cancelada ou modificada a qualquer momento
                      sem aviso prévio;<br /><br />
                      c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A promoção é aplicável somente às subscrições que exibem a mensagem
                      de oferta de desconto;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A promoção só pode ser usufruída pelo titular da conta que recebeu o
                      crédito, e não pode ser transferida
                      para terceiros;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      e)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A promoção não pode ser combinada com outras ofertas, a não ser que
                      a promoção indique o contrário;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      f)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A promoção é aplicável somente a pedidos finalizados e que estejam
                      de acordo com todos os
                      requerimentos da promoção;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      g)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Aquando da renovação (passado 12 meses), será aplicado o preço base
                      do plano e não o preço da
                      promoção.<br /><br />
                      3.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A subscrição será automaticamente renovada passado 12 meses, se o
                      Cliente não proceder ao
                      cancelamento da subscrição no prazo de 30 dias, a faturação para cobrança da subscrição será emitida
                      no
                      30º dia posterior ao da subscrição inicial. A subscrição é cobrada antecipadamente, e não é, em
                      qualquer
                      dos casos, reembolsável.<br /><br />
                      3.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não haverá reembolso ou créditos por meses parciais de
                      subscrição, restituições de upgrade/downgrade, ou
                      reembolsos por meses não utilizados com conta aberta. Valores pagos por subscrições não são
                      devolvidos
                      ou reembolsados em caso algum, incluindo em caso de cessão de utilização do website <a
                        href="www.ignoto.eu" target="_blank">www.ignoto.eu</a> ou
                      encerramento de conta.<br /><br />
                      3.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso o Cliente efetue ou pretenda um upgrade/downgrade do seu
                      plano, o pagamento desse será imediato
                      e automaticamente exigível, vencendo-se no período de faturação imediatamente posterior.<br /><br />
                      3.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A falta de pagamento da anuidade, confere direito à Dreamdeal de
                      resolver o contrato, nos termos do
                      disposto na Política de Cancelamento no ponto 8.<br /><br />
                      3.10. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Com base no princípio da igualdade não serão consideradas
                      quaisquer exceções.<br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="sla">
                  <v-card class="transparent">
                    <v-card-title>4. Serviços Mínimos (Service Level Agreement)</v-card-title>
                    <v-card-text>
                      4.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal compromete-se a fornecer Serviços com um Uptime de
                      96%, concedendo acesso a dados e
                      informações limitados à capacidade do Disco SSD selecionada, nos termos descritos nos pontos 2.5. e
                      2.6.<br /><br />
                      4.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se o serviço não for disponibilizado nos termos definidos no
                      ponto 4.1., estamos perante uma Quebra de
                      Serviço, indemnizável nos termos descritos no ponto 4.7.<br /><br />
                      4.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso o Cliente entenda que os serviços não estão a ser entregues
                      com a qualidade descrita no parágrafo
                      anterior, deve notificar o Apoio ao Cliente, por telefone ou correio eletrónico, no prazo máximo de
                      um dia
                      após a alegada quebra do serviço.
                      4.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Na notificação referida no ponto 4.3., o Cliente deve fornecer ao
                      Apoio ao Cliente uma descrição detalhada
                      da alegada quebra no fornecimento dos serviços, indicando, nomeadamente, a duração da mesma, URLs,
                      páginas e serviços afetados, bem como eventuais tentativas efetuadas para resolver o
                      sucedido.<br /><br />
                      4.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Apoio ao Cliente irá recolher toda a informação disponível
                      sobre o reportado, analisando cuidadosamente
                      a situação e decidindo se o sucedido é qualificável como Quebra de Serviço.<br /><br />
                      4.6. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não serão considerados Quebra de Serviço quaisquer problemas de
                      desempenho ou disponibilidade do
                      serviço devido a:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fatores alheios à Dreamdeal, ou que estejam fora do seu controlo
                      dentro dos limites da razoabilidade;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Fatores relacionados com o hardware ou software do Utilizador ou de
                      terceiros;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ações ou omissões do Cliente, de Colaboradores ou de
                      terceiros;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      d)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Utilização indevida ou incorreta do serviço pelo Cliente,
                      Colaboradores ou terceiros.<br /><br />
                      4.7. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso a situação seja qualificada pelo Apoio ao Cliente como
                      Quebra de Serviço, será aplicado um desconto
                      no período seguinte de faturação, calculado da seguinte forma: Desconto = (minutos da Quebra de
                      Serviço
                      × taxa de subscrição anual do Cliente) ÷ minutos totais correspondentes ao ano, consoante o
                      caso.<br /><br />
                      4.8. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No caso de ser notificada pelo Cliente de uma alegada Quebra de
                      Serviço, a análise de informação do Cliente
                      pela Dreamdeal será sempre efetuada ao abrigo da legislação portuguesa em matéria de proteção de
                      dados
                      e do compromisso de confidencialidade conforme a seguinte <u>Política de
                        Privacidade</u>.<br /><br />
                      4.9. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O disposto no número anterior, não se aplica às subscrições
                      gratuitas.<br /><br />
  
                    </v-card-text>
                  </v-card>
                </div>
                <div id="use_of_service">
                  <v-card class="transparent">
                    <v-card-title>5. Utilização dos Serviços pelo Cliente </v-card-title>
                    <v-card-text>
                      5.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Cliente deve garantir que os seus Colaboradores utilizam os
                      Serviços e o software IGNOTO apenas para
                      os fins a que se destinam, e de acordo com as instruções contidas nos manuais, orientações, os
                      termos da
                      garantia e outros eventuais termos e condições aplicáveis;<br /><br />
                      5.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;É expressamente vedado o uso dos Serviços para fins contrários à
                      lei portuguesa.<br /><br />
                      5.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Eventuais quebras de funcionamento, erros ou anomalias no
                      software devem ser reportadas pelo Cliente ao
                      Apoio ao Cliente, por telefone ou correio eletrónico, no prazo máximo de um dia.
                      5.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso se verifique que o Serviço está a ser utilizado para fins
                      contrários à lei ou não autorizados, a Dreamdeal
                      reserva-se o direito de resolver o contrato e/ou suspender o Serviço.<br /><br />
                      5.5. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal não se responsabiliza pela eventual insuficiência dos
                      Serviços para fazer face a expectativas
                      quanto à prestação, benefícios ou efeitos esperados no negócio ou produtividade do Cliente, que não
                      as
                      descritas e previstas nestes Termos e Condições.<br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="force">
                  <v-card class="transparent">
                    <v-card-title>6. Força Maior</v-card-title>
                    <v-card-text>
                      6.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal não se responsabiliza por todo e qualquer eventual
                      incumprimento do Serviço devido a motivos
                      de força maior.<br /><br />
                      6.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entende-se por “motivos de força maior” toda e qualquer
                      circunstância ou ocorrência que esteja fora do
                      controlo razoável da Dreamdeal, independentemente da sua previsibilidade à data do Contrato, e em
                      virtude
                      da qual não seria razoável exigir que a realização ou cumprimento das suas obrigações, incluindo,
                      sem
                      limitações, casos fortuitos, catástrofes naturais (incluindo terramotos, trovoada, furacões,
                      tempestades,
                      inundações, atividade vulcânica ou outras condições meteorológicas extremas), greves, bloqueios,
                      conflito
                      armado, ataque terrorista, golpe de estado, motins, sabotagem, vandalismo, avarias em instalações ou
                      máquinas, falha ou falta de energia elétrica, ataques cibernéticos e pirataria ou incumprimento por
                      parte de
                      fornecedores da Dreamdeal ou por quaisquer terceiros dos quais os Serviços estejam
                      dependentes.<br /><br />
                      6.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Se houver razões para querer que o motivo de força maior se
                      prolongará por mais de três meses
                      consecutivos, a Dreamdeal reserva-se o direito de resolver o Contrato, nos termos da Política de
                      Cancelamento descrita no ponto 8.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="responsibilty">
                  <v-card class="transparent">
                    <v-card-title>7. Garantia e Responsabilidade Civil </v-card-title>
                    <v-card-text>
                      7.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os presentes Termos e Condições não conferem ao Cliente o direito
                      a exigir da Dreamdeal qualquer
                      indemnização a título de responsabilidade civil, contratual ou extracontratual, por eventuais (i)
                      lucros
                      cessantes; (ii) perda de vendas ou o negócio; (iii) perda de acordos ou contratos; (iv) perda de
                      poupanças
                      antecipáveis; (v) perda de uso ou corrupção de software, dados ou informações; (vi) perda ou dano de
                      goodwill e (vii) danos indiretos ou consequentes.<br /><br />
                      7.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;NA EXTENSÃO PERMITIDA POR LEI, E SEM PREJUÍZO DAS EXCLUSÕES E
                      LIMITAÇÕES PREVISTAS
                      NESTES TERMOS E CONDIÇÕES, EXCLUÍMOS TODAS AS GARANTIAS E RENUNCIAMOS A TODA A
                      RESPONSABILIDADE POR QUALQUER ATO OU OMISSÃO DO UTILIZADOR OU DE TERCEIRO.<br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="close_policy">
                  <v-card class="transparent">
                    <v-card-title>8. Política de Cancelamento</v-card-title>
                    <v-card-text>
                      8.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Dreamdeal reserva-se o direito de resolver unilateralmente o
                      contrato nos seguintes casos:<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      a)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Falta de pagamento de qualquer uma das prestações na respetiva data
                      de vencimento, conforme descritono ponto 3.;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      b)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Utilização indevida do Serviço pelo Cliente, conforme descrito no
                      ponto 5.;<br /><br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      c)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Motivo de força maior com duração previsivelmente superior a três
                      meses consecutivos, conforme descrito no ponto 6.;<br /><br />
                      8.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;O Cliente pode denunciar o contrato, interpelando a Dreamdeal,
                      por escrito, com a antecedência mínima de
                      30 dias, bastando para tal registar o pedido através da sua área pessoal (opções, descativar
                      conta<br /><br />
                      8.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não haverá reembolso ou créditos por meses parciais de
                      subscrição, restituições de upgrade/downgrade, ou
                      reembolsos por meses não utilizados com conta aberta. Valores pagos por subscrições não são
                      devolvidos
                      ou reembolsados em caso algum, incluindo em caso de cessão de utilização do website <a
                        href="www.ignoto.eu" target="_blank">www.ignoto.eu</a> ou
                      encerramento de conta.<br /><br />
                      8.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Em caso de denúncia pelo Cliente, este continua a considerar-se
                      devedor de quaisquer quantias
                      eventualmente não pagas durante o período em que o Serviço esteve ativo.<br /><br />
                    </v-card-text>
                  </v-card>
                </div>
                <div id="final">
                  <v-card class="transparent">
                    <v-card-title>9. Disposições finais</v-card-title>
                    <v-card-text>
                      9.1. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para efeito do cumprimento do presente Contrato, quaisquer
                      comunicações com a Dreamdeal devem ser
                      efetuadas para os seguintes contactos::<br /><br />
                      <ul>
                        <li>info@ignoto.eu</li>
                        <li>+351 220 280 430</li>
                      </ul><br />
                      9.2. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Todo e qualquer litígio ou dúvida emergente da interpretação,
                      integração ou execução, cumprimento e
                      validade do presente contrato e que não se mostre consensualmente superado, será dirimido com
                      recurso
                      às vias judiciais e/ou a meios de resolução alternativa de litígios disponíveis nos termos da lei
                      portuguesa,
                      por exclusão de quaisquer outras.<br /><br />
                      9.3. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Este contrato rege-se pela lei portuguesa, designadamente pelo
                      disposto no Decreto-Lei n.º 252/94, de 20
                      de outubro.<br /><br />
                      9.4. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ao aceitar os termos deste contrato, o Comerciante aceita em
                      ficar vinculado ao conteúdo dos mesmos.
                    </v-card-text>
                  </v-card>
                </div>
                <div id="gloss">
                  <v-card class="transparent">
                    <v-card-title>Glossário</v-card-title>
                    <v-card-text>
                      Ao longo destes termos e condições, há expressões que se repetem, e que simplificamos para uma mais
                      fácil
                      leitura. Para não deixar dúvidas sobre os respetivos significados, deixamos aqui uma lista:
                      <ul>
                        <li>
                          <b>APIs</b> - Conjuntos de ferramentas, definições e protocolos para a criação de aplicações de
                          software;
                        </li>
                        <li>
                          <b>Cliente</b> - Pessoa singular ou coletiva que pretende efetuar uma subscrição de uso do
                          software IGNOTO,
                          em qualquer das modalidades, e que é parte deste acordo. Os dados do cliente são os que constam
                          do
                          Formulário, introduzidos pelo próprio Cliente ou pelo seu Representante, e dão-se por
                          inteiramente
                          reproduzidos para os devidos efeitos legais;
                        </li>
                        <li>
                          <b>Colaborador</b> (ou <b>Colaboradores</b>) - Pessoas autorizadas pelo Cliente para usar os
                          serviços subscritos,
                          no âmbito de relação laboral ou de prestação de serviços com aquele;
                        </li>
                        <li>
                          <b>Disco SSD</b> - Disco sólido de armazenamento de dados informáticos;
                        </li>
                        <li>
                          <b>Dispositivo ou Dispositivos</b> - Qualquer computador, tablet ou smartphone que o Utilizador
                          possa estar a usar para aceder aos Serviços;
                        </li>
                        <li>
                          <b>Formulário</b> - Formulário de contacto disponível na página ignoto.eu, através do qual é
                          escolhida a subscrição e inscritos os dados da pessoa singular ou coletiva Cliente;
                        </li>
                        <li>
                          <b>IGNOTO (“software IGNOTO”, “plataforma IGNOTO”)</b> - Designação coletiva do Serviço de
                          software online, de gestão de denúncias, pertencente à Dreamdeal Techologies, Unipessoal, Lda.,
                          objeto dos presentes termos e condições;
                        </li>
                        <li>
                          <b>Representante do Cliente</b> (ou <b>“Representante”</b>) - Pessoa singular que age em
                          representação do Cliente, caso este seja pessoa coletiva;
                        </li>
                        <li>
                          <b>Serviço</b> (ou <b>Serviços</b>) - Designação coletiva para toda a qualquer informação,
                          ferramenta, funcionalidades, acessíveis através de ignoto.eu, através de APIs, ou de qualquer
                          outro software ou website que se interligue com a plataforma IGNOTO;
                        </li>
                        <li>
                          <b>Utilizador</b> - Qualquer pessoa, Cliente ou Colaborador, que utilize os Serviços.
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </div>
              </v-col>
              <div class="sroll-down text-center">
                <p class="text-uppercase mb-0">Scroll Down</p>
                <v-icon>mdi-chevron-down</v-icon>
              </div>
            </v-row>
          </div>
        </v-form>
      </div>
    </div>
  </template>
  
  <script>
  
  
  export default {
    name: 'TermsConditions',
    data() {
      return {
        isOpen: false,
      }
    },
    created() {
    },
    beforeCreate() {
      const el = document.body
      el.classList.remove('home')
      el.classList.remove('colorize')
    },
    methods: {
      onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
        if (scrollTop + clientHeight >= scrollHeight) {
          document.querySelector('.policy-content').className += ' active'
        } else {
          document.querySelector('.policy-content').classList.remove('active')
        }
      },
      toggleMenu(e) {
        e.preventDefault()
        e.stopPropagation()
        this.isOpen = !this.isOpen
      }
    }
  }
  </script>
  
  <style lang="scss">
  .vue-slider.policy .vert-custom-dot {
    border-color: #0837fb;
  }
  
  .contact-center {
    font-size: 18px;
  
    span {
      color: #0837fb;
    }
  }
  
  .page-title {
    font-family: "Bebas Neue", monospace;
    font-size: 40px;
    color: #0837fb;
  }
  
  .sroll-down {
    position: absolute;
    bottom: 0;
    color: #FFFFFF;
    font-weight: bold;
    width: 105%;
    z-index: 5;
    padding-bottom: 25px !important;
    padding-top: 25px;
    transition: 0.4s all;
    opacity: 1;
  
    img {
      width: 25px;
    }
  
    &::after {
      background: rgb(0, 0, 0);
      background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6615021008403361) 18%, rgba(0, 0, 0, 1) 37%, rgba(0, 0, 0, 1) 77%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
      content: "";
      position: absolute;
      width: 100%;
      top: -45px;
      height: 148%;
      left: 0;
      z-index: -1;
    }
  }
  
  #policy-form {
    max-width: 1000px;
    padding: 2rem 5rem 0 5rem;
    position: relative;
    border-radius: 1rem;
  
    // margin-top: 7rem;
    .policy-content {
      overflow-y: auto;
      height: 85vh;
      scrollbar-width: none;
      -ms-overflow-style: none;
  
      &::-webkit-scrollbar {
        display: none;
      }
  
      &.active {
        .sroll-down {
          opacity: 0;
        }
      }
    }
  
    .form-content {
      position: relative;
      z-index: 5;
    }
  
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      margin: -5px;
      background: #000;
      border-radius: 0.8rem;
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 0;
      margin: -13px;
      background: linear-gradient(to bottom, #FFFFFF, #000000);
      border-radius: 1rem;
    }
  }
  
  @media only screen and (max-width : 1600px) {
    #policy-form {
      max-width: 850px;
      padding: 2rem 3rem 0 3rem;
      margin-top: 2rem;
  
      h3 {
        font-size: 1rem;
        margin: 0;
      }
    }
  }
  
  @media only screen and (max-width: 1263px) {
    #policy-form {
      padding: 40px;
      padding-top: 80px;
    }
  }
  </style>
  